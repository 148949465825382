import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import util from '../util';

const Banner = (props) => {

  if (props.fields) {
    
    let { bannerHeading, bannerContent, bannerHeadingType } = props.fields;

    bannerHeading.value = util.setVarLength(bannerHeading.value, 50);
    bannerContent.value = util.setVarLength(bannerContent.value, 150);

    return (
      <div className="container-fluid product-banner bg-product-banner text-white small">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pt-2 my-3">
              <Text tag={`${bannerHeadingType.fields.HeadingType.value}`} field={bannerHeading} />
              <RichText field={bannerContent} />
            </div>
          </div>
        </div>
      </div>
    );

  } else {
    return null;
  }

};

export default Banner;
