import React from "react";
import { Text, RichText, Image } from "@sitecore-jss/sitecore-jss-react";
import GenericCtaButton from "../GenericCtaButton";
import util from "../util";

const PromotionalCard = (props) => {

  if (props.data.fields) {
    let {
      Tagging,
      ProductTitle,
      ProductContent,
      LearnMore,
      LearnMoreCTAType,
      Collection
    } = props.data.fields;
    let ProductImage  = props.data.fields.ProductImage;

    
    ProductTitle.value = util.setVarLength(ProductTitle.value, 35);
    ProductContent.value = util.setVarLength(ProductContent.value, 150);
    LearnMore.value.text = util.setVarLength(LearnMore.value.text, 25);

    return (
      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
        <div className="product-tile promotional-tile bg-white text-center">
        <Text className="tagging" tag="p" field={Tagging} />
          {ProductImage.value.src !== undefined && ProductImage.value.src !== "" && (
            <div className="image-container">
               
              <Image field={ProductImage} />
            </div>
          )}
          <div className="tile-content">
            <div className="title">
              <RichText tag="h3" field={ProductTitle} />
            </div>
            <div className="content">
              <RichText field={ProductContent} />
            </div>
            <div className="CTA-container">
              <GenericCtaButton
                props={LearnMore}
                CTAType={
                  LearnMoreCTAType
                    ? LearnMoreCTAType.fields.ClassName.value
                    : "tertiary-w-icon"
                }
              />
            </div>
            
          </div>
         
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default PromotionalCard;
