import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import axios from 'axios';
import jwt from 'jwt-simple';
import {createTokenProvider} from '../../AwsTokenAuth';
import createDefaultStore from '../../SessionHandler';
import sessionconfig from '../../temp/sessionconfig';
import config from '../../temp/config';
import util from '../util';


export default class PastReports extends React.Component {

  constructor(props) {
    super(props);
    this.state = {reportList: [] };
  }

  async componentDidMount() {
    try {
      const storage = createDefaultStore({[sessionconfig.session.storageKey]: window.sessionStorage.getItem(sessionconfig.session.storageKey)});
      this.state = { tp: createTokenProvider(sessionconfig.session.storageKey, storage) };
  
      const data = {
        UserName: this.state.tp.getToken().fname 
      }

      // const res = await axios.get(`/profile.json`);
      // const res = await axios.get("https://ethos.dev.local/profile.json");
      const res = await axios.post(sessionconfig.API.userDataAPI, {
        accessToken: jwt.encode(data, config.apiJwtSecretKey)
      });

      await this.setState({ reportList: await this.sortReportList(res.data) });
  
    } catch (ex) {
        console.log('Exception occured: ' + ex);
    }
  }

  sortReportList(reportList) {
    return reportList.sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
  }

  render() {

    const { reportList } = this.state;
 
    if (this.props.fields) {

      let { Title, ProductLabel, DateCreated, NoReportsMsg } = this.props.fields;

      if (reportList) {

        return (
          <div className="container-fluid small herobanner">
            <div className="section-cecl bottom">
              <div className="cecl-bottom">
                <div className="row">
                  <div className="col-md-12">
                    <Text tag="label" className="title" field={Title} />
                  </div>
                </div>
                <div className="col-md-12 cecl-reports">
                  <div className="row heading">
                    <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3 heading-product">
                      { ProductLabel.value }
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-2 col-xl-2 heading-date">
                      { DateCreated.value }
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-6 col-xl-7 heading-download"></div>
                  </div>
                  {(reportList.length === 0) && (
                    <div className="no-records">
                      <RichText className="smaller no-records" field={NoReportsMsg} />
                    </div>
                  )}
                  {(reportList.length > 0) && (
                    reportList.map((listItem, index) => (
                      <React.Fragment key={index}>
                        <div className="row cecl-row">
                          <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3">
                            <div className="product-name">
                              <div className="row product-name">
                                <div style={{ width: "15%" }}>
                                  <a target="_blank" rel="noopener noreferrer" href={listItem.DownloadUrl}>
                                    <span>
                                      <img src={listItem.Icon} alt="" className="tile-image" />
                                    </span>
                                  </a>
                                </div>
                                <div style={{ width: "85%" }}>
                                  <a target="_blank" rel="noopener noreferrer" href={listItem.DownloadUrl}>
                                    <label>{ listItem.MediaItemName }</label>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-3 col-lg-2 col-xl-2 date-created">{ listItem.Date }</div>
                          <div className="col-sm-12 offset-md-2 col-md-2 offset-lg-4 col-lg-2 offset-xl-5 col-xl-2 download">
                            <a className="download-pdf" target="_blank" onClick={() => { util.downloadPdf(listItem.DownloadUrl, listItem.FileName) }}>Download</a>
                          </div>
                        </div>
                      </React.Fragment>
                    ))
                  )}
                  <br />
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="container-fluid small herobanner">
            <div className="section-cecl bottom">
              <div className="cecl-bottom">
                <div className="row">
                  <div className="col-md-12">
                    <div className="cecl-reports loader-div">
                      <div className="loader"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }
};

