exports.setVarLength = function (data, len) {
 
  let dataval=data.replace(/<[^>]+>/g, '');
  if (dataval.length > len) {
    data = dataval.slice(0, len);
  }

  return data;
};

exports.getDateTime = function (dt) {
  let d = new Date(dt * 1000);
  let options = { year: "numeric", month: "2-digit", day: "2-digit" };

  return (
    d.toLocaleDateString("en-US", options) +
    " " +
    d.toLocaleTimeString("en-US", {
        hour: "numeric",
        hour12: false,
        minute: "numeric",
        second: "numeric",
      })
      .toLowerCase()
      .replace(" ", "")
  );
};

exports.setProductName = function (productName) {
  sessionStorage.setItem("productName", productName);
};

var browser = function() {
  var test = function(regexp) {return regexp.test(window.navigator.userAgent)}
  switch (true) {
      case test(/edg/i): return "Microsoft Edge";
      case test(/trident/i): return "Microsoft Internet Explorer";
      case test(/firefox|fxios/i): return "Mozilla Firefox";
      case test(/opr\//i): return "Opera";
      case test(/ucbrowser/i): return "UC Browser";
      case test(/samsungbrowser/i): return "Samsung Browser";
      case test(/chrome|chromium|crios/i): return "Google Chrome";
      case test(/safari/i): return "Apple Safari";
      default: return "Other";
  }
};


exports.downloadPdf = function (fileUrl, fileName) {
  if(browser().indexOf('Google Chrome') > -1) {
    
    fetch(fileUrl)
    .then(response => {
        response.blob().then(blob => {
          // console.log('place 4')
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download =  fileName;
          a.click();
          // console.log('place 5')
        });
    });
  } else {
    window.open(fileUrl)
    // window.location.href = fileUrl;
  
  }
}
