import React from 'react';
//import { Text } from '@sitecore-jss/sitecore-jss-react';
//import GenericProductTile from '../GenericProductTile/index'

const GenericProduct = (props) => {
  //const { productList } = props.fields;
  return(
    <div></div>
    // <div className='generic-container'>
    //   <div className='row'>
    //     <Text tag='h3' field={props.fields.heading} />
    //   </div>

    //   <div className='row'>
    //       {productList &&
    //         productList.map((listItem, index) => (
    //           <div className='col-md-4 text-center' key={"t-"+index}>
    //            <GenericProductTile props={listItem} productProperty={{"isRecommended":false,"isSubscribed":false}}  />
    //           </div>
    //         )  
    //       )
    //     }
    //   </div>
    // </div>
)
};


export default GenericProduct;
