import React from 'react';

const Roles = (props) => {

  if (props.props.fields) {

    let RoleName = props.props.fields.RoleName.value;

    const opacityClass = (RoleName) === 'User' ? '' : 'opacityActive';

    return (
      <li className={`nav-item ${opacityClass}`}>
        <a className="nav-link" href="/#">{ RoleName }</a>
      </li>
    );
  
  } else {
    return null;
  }

};

export default Roles;
