import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';


const BrainBanner = (props) => {
  if (props.fields) {
    
    let { 
          description,
          heading,
          image

    } = props.fields;
    let backgroundImage = "url(" + image.value.src + ")";
    return (




<div class="container-fluid herobanner padding-top10 Bannerbackground"   style={{ backgroundImage: backgroundImage }}>

      <div class="MuiGrid-root MuiGrid-container MuiGrid-justify-xs-center" id="">
        <div class="jss40 Text-align-center">
          <div>
            <h2 className="MuiTypography-root MuiTypography-h2 MuiTypography-colorInherit MuiTypography-alignCenter MuiTypography-displayInline">
            <Text field={heading} /></h2>
          </div>
          <div className="bannerDescrition">
          <RichText field={description} />
            
          </div>
        </div>
       
      </div>
    </div> 
          
    );

  } else {
    return null;
  }

};

export default BrainBanner;
