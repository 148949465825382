import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';

const ProductInformationTeaser = (props) => {

  if (props.fields) {

    let { 
      HeadingType,
      SectionHeader,
      SectionDescription,
      LeftHeadingType, 
      LeftColHeader,
      LeftColDescription,
      LeftColImage,
      RightHeadingType,
      RightColHeader,
      RightColDescription,
      RightColImage
    } = props.fields;

    return(
      <div className="container-fluid product-banner bg-product-banner small bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-12 pt-2">
              <Text tag={`${HeadingType.value}`} field={SectionHeader} />
              <RichText field={SectionDescription} />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className='col-md-6 pt-2 my-3'>
              <Text tag={`${LeftHeadingType.value}`} field={LeftColHeader} />
              <RichText field={LeftColDescription} />
              <Image field={LeftColImage} />
            </div>
            <div className='col-md-6 pt-2 my-3'>
              <Text tag={`${RightHeadingType.value}`} field={RightColHeader} />
              <RichText field={RightColDescription} />
              <Image field={RightColImage}/>
            </div>
          </div>
        </div>
      </div>
    );
  
  } else {
    return null;
  }
  
};

export default ProductInformationTeaser;
