import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const GenericList1 = (props) => (
  <div className="container-fluid contentspot_style">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 col-md-12 col-lg-6 my-5 left_col">
      <Text tag="h2" field={props.fields.Heading} />
      
      </div>
    </div>
  </div>
</div>
);

export default GenericList1;
