import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import GenericCtaButton from "../GenericCtaButton";
import util from "../util";

const BannerWithNavigation = (props, history) => {
  if (props.fields) {
    let { Image, CTA, CTAType, BannerHeading, BannerContent } = props.fields;

    let backgroundImage = "url(" + Image.value.src + ")";
    CTA.value.text = util.setVarLength(CTA.value.text, 25);

    var productName = sessionStorage.getItem("productName");
    return (
      <div
        className="container-fluid herobanner herobannernav"
        style={{ backgroundImage: backgroundImage }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-7 col-lg-7 navigation leadgenheader">
              {CTA.value.text !== undefined && CTA.value.text !== "" && (
                <GenericCtaButton
                  props={CTA}
                  CTAType={
                    CTAType
                      ? CTAType.fields.ClassName.value
                      : "tertiary-left-icon"
                  }
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 my-5 left_col">
              <h1 className="max-font-weight text-white">
                <span className="product_name_heading">
                  {BannerHeading.value}{" "}
                  <span dangerouslySetInnerHTML={{ __html: productName }} />
                </span>
                <span className="all_product">
                  {BannerHeading.value} Ethos<sup>TM</sup>
                </span>
              </h1>
              {/* <Text
                tag="h1"
                className="max-font-weight text-white"
                field={BannerHeading}
              /> */}
              <RichText
                className="smaller text-white top_dis"
                field={BannerContent}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default BannerWithNavigation;
