import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import createDefaultStore from '../../SessionHandler';
import { createTokenProvider } from '../../AwsTokenAuth';
import sessionconfig from '../../temp/sessionconfig';
import util from '../util';


export default class HeroBanner extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      tp: '',
    };

  }


  componentDidMount() {
    
    const storage = createDefaultStore({ [sessionconfig.session.storageKey]: window.sessionStorage.getItem(sessionconfig.session.storageKey) });
    this.setState({ tp: createTokenProvider(sessionconfig.session.storageKey, storage) });
  
  }


  render() {
    if (this.props.fields !== undefined) {

      const { tp } = this.state;
      let auth_url = sessionconfig.aws.endpoint + sessionconfig.app.url;
      let { profileURL, logoutURL } = sessionconfig.path;
      let { BannerHeading, BannerContent } = this.props.fields;
      
      BannerHeading.value = util.setVarLength(BannerHeading.value, 50);
      BannerContent.value = util.setVarLength(BannerContent.value, 150);

      // if (isExperienceEditorActive() || tp) {
        return (
          <div className="container-fluid herobanner">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6 my-5">
                  <Text id="headerTitle" tag="h1" className="max-font-weight text-white" field={BannerHeading} />
                  <RichText id="headerContent" className="smaller text-white" field={BannerContent} />
                </div>
                {/* {(tp) && (
                <div className="col-sm-12 col-md-6 col-lg-6 text-right">
                  {(!tp.isLoggedIn()) && (
                    <div className="row profile-row">
                      <a href={auth_url} className="user">Login</a>
                    </div>
                  )} 
                  {(tp.isLoggedIn()) && (
                    <div className="row profile-row">
                      <div className="dropdown">
                        <a href="/#" className="dropdown-toggle user" data-toggle="dropdown">
                          Welcome {tp.getToken().fname} {tp.getToken().lname}
                        </a>
                        <div className="dropdown-menu">
                          <a href={profileURL} className="dropdown-item">My Profile</a>
                          <a href={logoutURL} className="dropdown-item">Logout</a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                )} */}
              </div>
            </div>
          </div>
        )
      // } 
      // else {
      //   // return <React.Fragment></React.Fragment>;
      //   return <div>This is tp</div>;
      // }
    } else {
      // return <React.Fragment></React.Fragment>;
      return <div className='no-data'></div>;
    }
  }

}
