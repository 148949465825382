import React from 'react';
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';

const DownloadableMedia = (props) => {

  if (props.props.fields) {

    let { Title, DocumentType, DownloadLink, Icon } = props.props.fields;
    
    return (
      <div className="DownloadableMedia">
        <div className="col-sm-12">
          <div className="DownloadableMedia-info float-left mx-4">
            <Text tag="p" className="DownloadableMedia-title float-left" field={Title} />
            <Text tag="p" className="DownloadableMedia-type float-right" field={DocumentType} />
          </div>
          <div className="DownloadableMedia-icon float-right py-1">
            <Link field={DownloadLink} download>
              <Image field={Icon} width="35" height="35" />
            </Link>
          </div>
        </div>
      </div>    
    );
  
  } else {
    return null;
  }

};

export default DownloadableMedia;
