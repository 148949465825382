import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import util from '../util';

const Banner = (props) => {

  if (props.fields) {
    
    let { BannerHeading, BannerContent, BannerHeadingType } = props.fields;
    
    BannerHeading.value = util.setVarLength(BannerHeading.value, 50);
    BannerContent.value = util.setVarLength(BannerContent.value, 150);

    return (
      <div className="container product-banner bg-product-banner text-white small">
        <div className="row">
          <div className="col-md-12 pt-2 my-3">
            <Text tag={`${BannerHeadingType.fields.HeadingType.value}`} field={BannerHeading} />
            <Text field={BannerContent} />
          </div>
        </div>
      </div>
    );

  } else {
    return null;
  }
  
};

export default Banner;
