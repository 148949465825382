import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';

const TwoColumnLayout = (props) => {

  if (props.fields) {
    
    let { 
      SectionBackground, 
      SectionHeader, 
      HeadingType, 
      SectionDescription, 
      LeftColHeader,
      LeftHeadingType,
      LeftColImage,
      LeftColDescription,
      RightColHeader,
      RightHeadingType,
      RightColImage,
      RightColDescription
    } = props.fields;

    let sectionClass = (SectionBackground) ? SectionBackground.fields.ClassName.value : 'section-bg-white';

    return (
      <div className={`container-fluid sections ${sectionClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {SectionHeader.value && (
                <div className="section-title">
                  <Text tag={`${HeadingType.value}`} field={SectionHeader} />
                </div>
              )}
              {SectionDescription.value && (
                <div className="editor-content">
                  <RichText field={SectionDescription} />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 sectionLeftCol">
              {LeftColHeader.value && (
                <div className="sub-section-title">
                  <Text tag={`${LeftHeadingType.value}`} field={LeftColHeader} />
                </div>
              )}
              {LeftColImage && (
                <div className="editor-content">
                  <Image field={LeftColImage} />
                </div>
              )}
              {LeftColDescription.value && (
                <div className="editor-content">
                  <RichText field={LeftColDescription} />
                </div>
              )}
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 sectionRightCol">
              {RightColHeader.value && (
                <div className="sub-section-title">
                  <Text tag={`${RightHeadingType.value}`} field={RightColHeader} />
                </div>
              )}
              {RightColImage && (
                <div className="editor-content">
                  <Image field={RightColImage}/>
                </div>
              )}
              {RightColDescription.value && (
                <div className="editor-content">
                  <RichText field={RightColDescription} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }

};

export default TwoColumnLayout;
