import React from "react";
import { Image, RichText ,Text} from "@sitecore-jss/sitecore-jss-react";
import config from "../../temp/config";
import GenericCtaButton from "../GenericCtaButton";
import util from "../util";

function validateCTAText(CTAArray) {
  for (let i = 0; i < CTAArray.length; i++) {
    if (
      CTAArray[i].fields.CTALink.value.text.length >
      config.productConfig.validation["GenericCTAText"]
    ) {
      CTAArray[i].fields.CTALink.value.text = CTAArray[
        i
      ].fields.CTALink.value.text.slice(
        0,
        config.productConfig.validation["GenericCTAText"]
      );
    }
  }
}

const GenericProductTile = ({
  props,
  productProperty = null,
  userLoggedIn = false,
}) => {
  if (props.fields) {
    const isRecommended =
      productProperty === null ? false : productProperty.isRecommended;
    const isSubscribed =
      productProperty === null ? false : productProperty.isSubscribed;
    const productClass = !userLoggedIn
      ? "unauthenticated-tile"
      : productProperty.class === null || productProperty.class === undefined
      ? ""
      : productProperty.class;
    const productTitle =
      productProperty.title === null || productProperty.title === undefined
        ? ""
        : productProperty.title;

    let {
      Tagging,
      ProductTitle,
      ProductContent,
      ProductImage,
      LearnMore,
      LearnMoreCTAType,
      Open,
      OpenCTAType,
      Manage,
      ManageCTAType,
      Subscribe,
      SubscribeCTAType,
      CTA,
      Collection,
    } = props.fields;

    validateCTAText(CTA);

    ProductTitle.value = util.setVarLength(ProductTitle.value, 75);
    ProductContent.value = util.setVarLength(ProductContent.value, 300);

    return (
      <div className={`product-tile text-white text-center ${productClass}`}>
         {(Tagging.value !== null) && (
                         <Text className="tagging" tag="p" field={Tagging} />
                  )} 
        {isRecommended && <div className="recommended-bar">{productTitle}</div>}
        <div className="tile-content">
          <div className="icon">
            <Image field={ProductImage} className="tile-image" />
          </div>
          <div className="title">
            <RichText tag="h3" field={ProductTitle} />
          </div>
          <div className="content">
            <RichText field={ProductContent} />
          </div>
          <div className="CTA-container">
            {!userLoggedIn &&
              CTA.map((listItem, index) => (
                <span key={"d" + index}>
                  {listItem.id ===
                    config.productConfig.ProductCTA["LearnMore"] &&
                    listItem.fields.OtherCTA.value === true && (
                      <GenericCtaButton
                        props={LearnMore}
                        CTAType={
                          LearnMoreCTAType
                            ? LearnMoreCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                </span>
              ))}
            {userLoggedIn &&
              CTA.map((listItem, index) => (
                <span key={"d" + index}>
                  {isSubscribed &&
                    listItem.id === config.productConfig.ProductCTA["Open"] &&
                    listItem.fields.SubscribedCTA.value === true && (
                      <GenericCtaButton
                        props={Open}
                        CTAType={
                          OpenCTAType
                            ? OpenCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                  {isRecommended &&
                    listItem.id === config.productConfig.ProductCTA["Open"] &&
                    listItem.fields.RecommendedCTA.value === true && (
                      <GenericCtaButton
                        props={Open}
                        CTAType={
                          OpenCTAType
                            ? OpenCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                  {!isRecommended &&
                    !isSubscribed &&
                    listItem.id === config.productConfig.ProductCTA["Open"] &&
                    listItem.fields.OtherCTA.value === true && (
                      <GenericCtaButton
                        props={Open}
                        CTAType={
                          OpenCTAType
                            ? OpenCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                  {isSubscribed &&
                    listItem.id === config.productConfig.ProductCTA["Manage"] &&
                    listItem.fields.SubscribedCTA.value === true && (
                      <GenericCtaButton
                        props={Manage}
                        CTAType={
                          ManageCTAType
                            ? ManageCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                  {isRecommended &&
                    listItem.id === config.productConfig.ProductCTA["Manage"] &&
                    listItem.fields.RecommendedCTA.value === true && (
                      <GenericCtaButton
                        props={Manage}
                        CTAType={
                          ManageCTAType
                            ? ManageCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                  {!isRecommended &&
                    !isSubscribed &&
                    listItem.id === config.productConfig.ProductCTA["Manage"] &&
                    listItem.fields.OtherCTA.value === true && (
                      <GenericCtaButton
                        props={Manage}
                        CTAType={
                          ManageCTAType
                            ? ManageCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                  {isSubscribed &&
                    listItem.id ===
                      config.productConfig.ProductCTA["Subscribe"] &&
                    listItem.fields.SubscribedCTA.value === true && (
                      <div
                        className="simpllink"
                        onClick={() => util.setProductName(ProductTitle.value)}
                      >
                        <GenericCtaButton
                          props={Subscribe}
                          CTAType={
                            SubscribeCTAType
                              ? SubscribeCTAType.fields.ClassName.value
                              : "tertiary-w-icon"
                          }
                          CTAData={listItem.fields}
                        />
                      </div>
                    )}
                  {isRecommended &&
                    listItem.id ===
                      config.productConfig.ProductCTA["Subscribe"] &&
                    listItem.fields.RecommendedCTA.value === true && (
                      <div
                        className="simpllink"
                        onClick={() => util.setProductName(ProductTitle.value)}
                      >
                        <GenericCtaButton
                          props={Subscribe}
                          CTAType={
                            SubscribeCTAType
                              ? SubscribeCTAType.fields.ClassName.value
                              : "tertiary-w-icon"
                          }
                          CTAData={listItem.fields}
                        />
                      </div>
                    )}
                  {!isRecommended &&
                    !isSubscribed &&
                    listItem.id ===
                      config.productConfig.ProductCTA["Subscribe"] &&
                    listItem.fields.OtherCTA.value === true && (
                      <div
                        className="simpllink"
                        onClick={() => util.setProductName(ProductTitle.value)}
                      >
                        <GenericCtaButton
                          props={Subscribe}
                          CTAType={
                            SubscribeCTAType
                              ? SubscribeCTAType.fields.ClassName.value
                              : "tertiary-w-icon"
                          }
                          CTAData={listItem.fields}
                        />
                      </div>
                    )}
                  {isSubscribed &&
                    listItem.id ===
                      config.productConfig.ProductCTA["LearnMore"] &&
                    listItem.fields.SubscribedCTA.value === true && (
                      <GenericCtaButton
                        props={LearnMore}
                        CTAType={
                          LearnMoreCTAType
                            ? LearnMoreCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                  {isRecommended &&
                    listItem.id ===
                      config.productConfig.ProductCTA["LearnMore"] &&
                    listItem.fields.RecommendedCTA.value === true && (
                      <GenericCtaButton
                        props={LearnMore}
                        CTAType={
                          LearnMoreCTAType
                            ? LearnMoreCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                  {!isRecommended &&
                    !isSubscribed &&
                    listItem.id ===
                      config.productConfig.ProductCTA["LearnMore"] &&
                    listItem.fields.OtherCTA.value === true && (
                      <GenericCtaButton
                        props={LearnMore}
                        CTAType={
                          LearnMoreCTAType
                            ? LearnMoreCTAType.fields.ClassName.value
                            : "tertiary-w-icon"
                        }
                        CTAData={listItem.fields}
                      />
                    )}
                </span>
              ))}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default GenericProductTile;
