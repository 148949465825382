import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';

const CardWithTwoBlocks = (props) => {

  if (props.fields) {
    
    let { 
      icon,
      title,
      description,
      direction,
      iconPosition,
      divider,
      dividerColorClass,
      titleTextClass,
      cardheading,
      cardheadingStyle,
      isLastElement,

      

    } = props.fields;

  

    return (


      
 <div class="sections section-bg-white-center" id="">
	<div class="container">
		<div class="MuiGrid-root row   MuiGrid-container MuiGrid-justify-xs-center" id=""></div>
		<div class="MuiGrid-root CardWithTwoBlocks-container MuiGrid-container MuiGrid-justify-xs-center" id="">
		<div class="MuiGrid-root MuiGrid-container MuiGrid-item MuiGrid-justify-xs-center MuiGrid-grid-sm-12 MuiGrid-grid-md-9 MuiGrid-grid-lg-9" id="">

     {(direction.value === "Right") && (             
        <div class=" RightDirection MuiGrid-root jss42 jss48 MuiGrid-container" id="">
					<div class="MuiGrid-root MuiGrid-container25 MuiGrid-item MuiGrid-grid-sm-2 MuiGrid-grid-md-2 MuiGrid-grid-lg-2" id="">
						<div class="jss53">
            <Image field={icon} />
						</div>
					</div>

           
          <div className="MuiGrid-container60">
                <div class="MuiGrid-root jss54  MuiGrid-container MuiGrid-item MuiGrid-grid-sm-8 MuiGrid-grid-md-8 MuiGrid-grid-lg-8" id="">
                {(dividerColorClass.value === "LightPurple") && (
                  <h2 className=" jssLightPurpleText MuiTypography-root jss43 jss46 MuiTypography-h2 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={title} />
                  </h2>
                )} 

                {(dividerColorClass.value === "Purple") && (
                  <h2 className=" jssPurpleText MuiTypography-root jss43 jss46 MuiTypography-h2 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={title} />
                  </h2>
                )} 

                {(dividerColorClass.value === "Blue") && (
                  <h2 className="jssBlueText MuiTypography-root jss43 jss46 MuiTypography-h2 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={title} />
                  </h2>
                )} 

                {(dividerColorClass.value === "Blue") && (
                  <h4 class=" jssBlueText jssTextStyle MuiTypography-root jss43 MuiTypography-h4 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={cardheading} />
                  </h4>   
                )}

                {(dividerColorClass.value === "Purple") && (
                  <h4 class=" jssPurpleText jssTextStyle MuiTypography-root jss43 MuiTypography-h4 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={cardheading} />
                  </h4>   
                )}

                {(dividerColorClass.value === "LightPurple") && (
                  <h4 class=" jssLightPurpleText jssTextStyle MuiTypography-root jss43 MuiTypography-h4 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={cardheading} />
                  </h4>   
                )}
                  <RichText field={description} />

                  {(dividerColorClass.value === "LightPurple") && (
                            <div className="jss49 jssLightPurple"></div>
                  )} 

                  {(dividerColorClass.value === "Purple") && (
                          <div className="jss49 jssPurple"></div>
                  )} 

                {(dividerColorClass.value === "Blue") && (
                        <div className="jss49 jssBlue"></div>
                )} 

                 
                 
            </div>
					</div>
        
      
				</div>
        
          )}	
    
      {(direction.value === "Left") && (             
        <div class="MuiGrid-root jss42 jss48 MuiGrid-container" id="">
					<div class="MuiGrid-root MuiGrid-container25 MuiGrid-item MuiGrid-grid-sm-2 MuiGrid-grid-md-2 MuiGrid-grid-lg-2" id="">
						<div class="jss53">
            <Image field={icon} />
						</div>
					</div>

           
          <div className="MuiGrid-container60">
                <div class="MuiGrid-root jss54  MuiGrid-container MuiGrid-item MuiGrid-grid-sm-8 MuiGrid-grid-md-8 MuiGrid-grid-lg-8" id="">
                {(dividerColorClass.value === "LightPurple") && (
                  <h2 className=" jssLightPurpleText MuiTypography-root jss43 jss46 MuiTypography-h2 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={title} />
                  </h2>
                )} 

                {(dividerColorClass.value === "Purple") && (
                  <h2 className=" jssPurpleText MuiTypography-root jss43 jss46 MuiTypography-h2 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={title} />
                  </h2>
                )} 

                {(dividerColorClass.value === "Blue") && (
                  <h2 className="jssBlueText MuiTypography-root jss43 jss46 MuiTypography-h2 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={title} />
                  </h2>
                )} 

                {(dividerColorClass.value === "Blue") && (
                  <h4 class=" jssBlueText jssTextStyle MuiTypography-root jss43 MuiTypography-h4 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={cardheading} />
                  </h4>   
                )}

                {(dividerColorClass.value === "Purple") && (
                  <h4 class=" jssPurpleText jssTextStyle MuiTypography-root jss43 MuiTypography-h4 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={cardheading} />
                  </h4>   
                )}

                {(dividerColorClass.value === "LightPurple") && (
                  <h4 class=" jssLightPurpleText jssTextStyle MuiTypography-root jss43 MuiTypography-h4 MuiTypography-colorInherit MuiTypography-alignLeft MuiTypography-displayInline">
                  <Text field={cardheading} />
                  </h4>   
                )}
                  <RichText field={description} />

                  {(dividerColorClass.value === "LightPurple") && (
                            <div className="jss49 jssLightPurple"></div>
                  )} 

                  {(dividerColorClass.value === "Purple") && (
                          <div className="jss49 jssPurple"></div>
                  )} 

                {(dividerColorClass.value === "Blue") && (
                        <div className="jss49 jssBlue"></div>
                )} 

                 
                 
            </div>
					</div>
        
      
				</div>
        
          )}
      
			</div>

		</div>
	</div>
  	</div>

       

    );

  } else {
    return null;
  }

};

export default CardWithTwoBlocks;
