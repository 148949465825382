import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';

const FullSectionTeaser = (props) => {

  if (props.fields) {

    let { Title, TitleType, Description } = props.fields;
    let imageIcon = props.fields.Image;

    if ((props.fields.Title.value && (props.fields.Title.value !== null)) || 
    (props.fields.Description.value && (props.fields.Description.value !== null))) {

      return (
        <div className="container-fluid sections section-bg-blue">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 my-3">
                {(Title.value) && (Title.value !== null) && (
                  <Text tag={`${TitleType.value}`} field={Title} />
                )}
                {(imageIcon) && (
                  <div className="editor-content">
                    <Image field={imageIcon} />
                  </div>
                )}
                {(Description.value) && (Description.value !== null) && (
                  <div className="editor-content graph_content">
                    <RichText field={Description} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
      
    } else {
      return null;
    }

  } else {
    return null;
  } 
  
};

export default FullSectionTeaser;
