import React from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import DownloadableMedia from '../DownloadableMedia';
import util from '../util';

const TwoColumnLayoutWithList = (props) => {

  if (props.fields) {
  
    let { 
      SectionBackground, 
      SectionHeader, 
      SectionDescription, 
      HeadingType, 
      LeftColHeader, 
      LeftHeadingType,
      LeftColImage,
      LeftColDescription,
      RightColHeader, 
      RightHeadingType,
      RightColImage,
      RightColDescription,
      DownloadBars 
    } = props.fields;

    let sectionClass = (SectionBackground) ? SectionBackground.fields.ClassName.value : 'bg-white';

    RightColHeader.value = util.setVarLength(RightColHeader.value, 50);
    
    return (
      <div className={`container-fluid sections ${sectionClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 pt-2 my-3">
              {SectionHeader.value && (
                <div className="section-title">
                  <Text tag={`${HeadingType.value}`} field={SectionHeader}/>
                </div>
              )}
              {SectionDescription.value && (
                <div className="editor-content">
                  <RichText field={SectionDescription} />
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 pt-2 my-3 sectionLeftCol">
              {LeftColHeader.value && (
                <div className="sub-section-title">
                  <Text tag={`${LeftHeadingType.value}`} field={LeftColHeader} />
                </div>
              )}
              {LeftColImage && (
                <div className="editor-content">
                  <Image field={LeftColImage} />
                </div>
              )}
              {LeftColDescription.value && (
                <div className="editor-content">
                  <RichText field={LeftColDescription} />
                </div>
              )}
            </div>

            <div className="col-sm-12 col-md-6 pt-2 my-3 sectionRightCol">
              {RightColHeader.value && (
                <div className="sub-section-title">
                  <Text tag={`${RightHeadingType.value}`} field={RightColHeader} />
                </div>
              )}
              {RightColImage && (
                <div className="editor-content">
                  <Image field={RightColImage} />
                </div>
              )}
              {RightColDescription.value && (
                <div className="editor-content">
                  <RichText field={RightColDescription} />
                </div>
              )}
              {(DownloadBars.length > 0) && (
                <div>
                  {DownloadBars &&
                    DownloadBars.map((listItem, index) => (
                      <React.Fragment key={index}>
                        <DownloadableMedia props={listItem} />
                      </React.Fragment>
                    )
                  )} 
                </div>
              )}
            </div> 
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }

};

export default TwoColumnLayoutWithList;
