const createDefaultStore = (initData = {}) => {

    const data = initData;

    const getData = () => data; 

    const getItem = (key) => {
        return data[key];
    };

    const setItem = (key, value) => {
        data[key] = value;
        window.sessionStorage.setItem(key, value);
    };

    const removeItem = (key) => {
        delete data[key];
        window.sessionStorage.removeItem(key);
    };

    return { getItem, setItem, removeItem, getData }
};

export default createDefaultStore;
