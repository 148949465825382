import React, { useState, useEffect } from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { Modal } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import Roles from '../Roles';
import ProList from '../ProList';
import createDefaultStore from '../../SessionHandler';
import { createTokenProvider } from '../../AwsTokenAuth';
import sessionconfig from '../../temp/sessionconfig';
import util from '../util';

const client = new ApolloClient({
  uri: sessionconfig.apolloClient.url,
});

const UserProfile = (props) => {
    
    let { 
      EmailLable, 
      EmailNotificationLable, 
      EmailNotification, 
      RolesLabel, 
      LastLogonLable, 
      LastUsageLable, 
      ProductTabLable, 
      ClosePopupIcon 
    } = props.fields;

    let session_url = sessionconfig.aws.endpoint + sessionconfig.app.url;

    const [show, setShow] = useState(true);
    const [key, setKey] = useState(ProductTabLable.value);
    const [tp, setTp] = useState();

    const handleClose = () => {
      setShow(false)
    };

    let firstName = "",
        lastName = "",
        emailId = "",
        lastlogin = "",
        LastUsage = "",
        userRoles = props.fields.Roles;

    useEffect(() => {
      const storage = createDefaultStore({ [sessionconfig.session.storageKey]: window.sessionStorage.getItem(sessionconfig.session.storageKey) });
      setTp( createTokenProvider(sessionconfig.session.storageKey, storage) );
    }, []);

    if (tp) {

      firstName = tp.getToken() && tp.getToken().fname;
      lastName = tp.getToken() && tp.getToken().lname;
      emailId = tp.getToken() && tp.getToken().email;
      lastlogin = tp.getToken() && tp.getToken().authTime;
      
      if (props.fields) {
      return (
        <div className="container-fluid herobanner" style={{ height: "100vh" }}>
          <div className="row">
            <div className="container">
              <Modal className="user-profile-modal" show={show} backdrop="static" onHide={handleClose} size="xl">
                <Modal.Body className="modal-main-content">
                  {(ClosePopupIcon.value.src !== undefined) &&
                  (ClosePopupIcon.value.src !== "") && (
                    <a href={session_url}>
                      {(window.self === window.top) && (
                      <Image id="closeBtn" className="icon-dark-x-close user-profile-close-btn closeButton" field={ClosePopupIcon} alt="close" onClick={handleClose} />
                      )}
                    </a>
                  )}
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 user-profile-left-panel">
                      <h2 className="user-title">{ firstName } { lastName }</h2>
                      <hr />
                      <Text className="role-heading" tag="h5" field={EmailLable} />
                      <div className="email-details-div mr-5">
                        <p className="float-left user-email">{ emailId }</p>
                        <label className="float-right email-notifications">
                          <input 
                            type="radio" 
                            className="email-radio"
                            value={EmailNotificationLable.value}
                            checked={EmailNotification.value}
                            disabled
                          />
                          { EmailNotificationLable.value }
                        </label>
                      </div>
                      <div className="role-div">
                        <Text className="role-heading" tag="h5" field={RolesLabel} />
                        {(userRoles.length > 0) && (
                          <ul className="nav">
                            {userRoles && userRoles.map((listItem, index) => (
                              <React.Fragment key={index}>
                                <Roles props={listItem} />
                              </React.Fragment>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 user-profile-right-panel">
                      <div className="login-details">
                        <Text className="float-left last-login" tag="p" field={LastLogonLable}/>{" "}
                        <p className="float-left last-login" style={{ paddingLeft: "10px" }}>
                          { util.getDateTime(lastlogin) }
                        </p>
                        {LastUsage ? (
                          <React.Fragment>
                            <p className="float-right last-usage" style={{ paddingLeft: "10px" }}>
                              { util.getDateTime(LastUsage) }
                            </p>
                            <Text className="float-right last-usage" tag="p" field={LastUsageLable} />
                          </React.Fragment>
                        ) : (
                          <React.Fragment> </React.Fragment>
                        )}
                      </div>

                      <div className="navigation-bar">
                        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)}>
                          <Tab eventKey={ProductTabLable.value} title={ProductTabLable.value}>
                            <div id="product-tab-container" className="product-tab-container">
                              <ApolloProvider client={client}>
                                <ProList prodata={props.fields} />
                              </ApolloProvider>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
          <style jsx>
            {`
              .modal-backdrop {background: none;}
              .modal {top: 0;}
            `}
          </style>
        </div>
      );
    } else {
      return null;
    }

  } else {
    return null;
  }

};

export default UserProfile;
