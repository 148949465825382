import React from "react";
import { Text, Link, RichText } from "@sitecore-jss/sitecore-jss-react";

const GlobalFooter = (props) => {
  if (props.fields) {
    let {
      CopyrightText,
      HelpLink,
      PrivacyPolicyLink,
      SupportNumber,
      SupportText,
    } = props.fields;
    let support_number = "tel:" + SupportNumber.value;

    if (
      CopyrightText.value !== "" ||
      HelpLink.value.href !== "" ||
      PrivacyPolicyLink.value.href !== "" ||
      SupportNumber.value !== "" ||
      SupportText.value !== ""
    ) {
      return (
        <div className="container-fluid footer">
          <div className="row">
            <div className="col-sm-12 col-md-8 col-lg-8 footer_left">
              <RichText id="footerCText" field={CopyrightText} />
              <Link id="footerPLink" field={PrivacyPolicyLink} />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 footer_right">
              <RichText id="footerSText" field={SupportText} />
              <span className="support_number">
                <a id="footerSNumber" href={support_number}>
                  {SupportNumber.value}
                </a>
              </span>
              <Link id="footerHelpLink" field={HelpLink} />
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default GlobalFooter;
