import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import GenericProductTile from '../GenericProductTile';
import config from '../../temp/config';
import createDefaultStore from '../../SessionHandler';
import { createTokenProvider } from '../../AwsTokenAuth';
import sessionconfig from '../../temp/sessionconfig';
import util from '../util';

function getProductType(item, isLoggedIn) {

  let productProperty;
  
  if (!isLoggedIn) {
    productProperty = {
      isRecommended: false,
      isSubscribed: false,
      class: config.productConfig.AllProducts.className,
      title: config.productConfig.AllProducts.title
    };
  } else {
    productProperty = {
      isRecommended: ((item.fields.ProductStatus !== null) && (item.fields.ProductStatus.id === config.productConfig.productRecommendedItemId)) ? true : false,
      isSubscribed: ((item.fields.ProductStatus !== null) && (item.fields.ProductStatus.id === config.productConfig.productSubscribedItemId)) ? true : false,
      class: ((item.fields.ProductStatus === null) || (item.fields.ProductStatus.fields.StyleClass === null)) ? "" : item.fields.ProductStatus.fields.StyleClass.fields.className.value,
      title: (item.fields.ProductStatus === null) ? "" : item.fields.ProductStatus.fields.Title.value
    };
  }

  return productProperty;

}

function getIndex(value, arr) {
  for(let i = 0; i < arr.length; i++) {
    if(arr[i] === value) {
        return i;
    }
  }
  return -1;
}

function sortProductList(unsortProductList) {
  let productSortOrder = config.productConfig.productSortOrder;
  const sortProductLists=[...unsortProductList].sort((a, b) => 
  (getIndex((a.fields.ProductStatus!=null?a.fields.ProductStatus.id:""), productSortOrder) < getIndex((b.fields.ProductStatus!=null?b.fields.ProductStatus.id:""), productSortOrder)) ? 1 : -1)
  return sortProductLists;
}

export default class RelatedCards extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      tp: ''
    }

  }

  componentDidMount() {
    
    const storage = createDefaultStore({ [sessionconfig.session.storageKey]: window.sessionStorage.getItem(sessionconfig.session.storageKey) });
    this.setState({ tp : createTokenProvider(sessionconfig.session.storageKey, storage) });
  
  }

  render() {

    const { tp } = this.state;
  
    if (this.props.fields) {

      let { Cards, Title, HeadingType, Description} = this.props.fields;

      Title.value = util.setVarLength(Title.value, 75);
      Description.value = util.setVarLength(Description.value, 300);

      if (tp) {

        let cardList = (tp.isLoggedIn()) ? sortProductList(Cards) : Cards;
        
        return (
          <div id="data-packs"  className={`container-fluid sections section-bg-blue product-container`}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {Title.value && (
                    <div className="section-title">
                      <RichText tag={`${HeadingType.value}`} field={Title} />
                    </div>
                  )}
                  {Description.value && (
                    <div className="editor-content">
                      <RichText field={Description} />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                {cardList &&
                  cardList.map((listItem, index) => (
                    <React.Fragment key={index}>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 mx-auto">
                        <GenericProductTile 
                          props={listItem} 
                          productProperty={getProductType(listItem, tp.isLoggedIn())} 
                          userLoggedIn={tp.isLoggedIn()} 
                        />
                      </div>
                    </React.Fragment>
                  ))
                }
              </div>
            </div>
          </div>
        );
      } else {
        return null;
        // return (
        //   <div className="container-fluid product-container loader-div">
        //     <div className="container">
        //       <div className="loader"></div>
        //     </div>
        //   </div>
        // );
      }
    } else {
      return null;
    }
  }

}
