import { Form } from "@sitecore-jss/sitecore-jss-react-forms";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import { sitecoreApiHost, sitecoreApiKey } from "../../temp/config";
import "./LeadGenForm.css";
import createDefaultStore from "../../SessionHandler";
import { createTokenProvider } from "../../AwsTokenAuth";
import sessionconfig from "../../temp/sessionconfig";
import parse from "html-react-parser";

const LeadGenForm = ({ fields, history }) => {
  const [tp, setTp] = useState();

  let firstName = "",
    lastName = "",
    emailId = "",
    productName = "";

  useEffect(() => {
    const storage = createDefaultStore({
      [sessionconfig.session.storageKey]: window.sessionStorage.getItem(
        sessionconfig.session.storageKey
      ),
    });
    setTp(createTokenProvider(sessionconfig.session.storageKey, storage));

    $(document).submit(function (event) {
      event.preventDefault();
      lastHeight = $(".formdiv").height();
      checkError = true;
    });

    let checkError = false;
    let lastHeight = 0;
    let thankyou_msg = false;

    function checkHeightChange() {
      if (checkError) {
        if ($(".invalid").length) {
          $("label.invalid:first").next().focus();
          $(".lds-dual-ring").css("display", "none");
          $(".overlay").css("display", "none");
          checkError = false;
        } else {
          $(".lds-dual-ringr").css("display", "block");
          $(".overlay").css("display", "block");
        }
      }
      if (!thankyou_msg) {
        if ($(".thankyou_msg").length) {
          thankyou_msg = true;
          $("html,body")
            .stop()
            .animate(
              {
                scrollTop: $(".formdiv").offset().top,
              },
              1000
            );
        }
      }
      if ($(".thankyou_msg").length) {
        sessionStorage.removeItem("productName");
        $(".lds-dual-ring").css("display", "none");
        $(".overlay").css("display", "none");
      }

      let newHeight = $(".formdiv").height();
      let formPositionTop = parseInt($(".leadform_container").position().top);

      let contentspotHeight = $(".contentspot_style").length
        ? parseInt($(".contentspot_style").height())
        : 0;
      let herobannernavHeight = $(".herobannernav").length
        ? parseInt($(".herobannernav").height())
        : 0;
      if (
        contentspotHeight + herobannernavHeight <=
        formPositionTop + newHeight
      ) {
        $(".footer").addClass("fixed-footer");
        $(".footer").css("top", formPositionTop + newHeight + 100 + "px");
      } else {
        $(".footer").css("top", "0");
        $(".footer").removeClass("fixed-footer");
      }

      if (lastHeight !== newHeight) {
        lastHeight = newHeight;
      }
    }

    $(document).ready(function () {
      $("#root").removeClass("background");
      $(".herobanner").addClass("background");
      $(".footer").addClass("footer_background");
      $("#root").css("background", "#fff");
      setInterval(checkHeightChange, 500);
      $(".form-control:eq(1)").focus();
      $(".product_name").attr("disabled", true);
    });

    $(document).on("change", "select", function () {
      if ($(this).val().toUpperCase() === "OTHERID") {
        $(this).parent().find(".hide_box").show();
      } else {
        $(this).parent().find(".hide_box").hide();
      }
    });
  }, []);

  if (tp) {
    firstName = tp.getToken() && tp.getToken().fname;
    lastName = tp.getToken() && tp.getToken().lname;
    emailId = tp.getToken() && tp.getToken().email;
    productName = sessionStorage.getItem("productName");
    if (productName) {
      productName = parse(sessionStorage.getItem("productName"));
    }

    if (!productName) {
      $(".product_name").css("display", "none");
    }
  }

  if (productName) {
    $(".product_name_heading").css("display", "block");
    $(".all_product").css("display", "none");
  }
  if (!productName) {
    $(".product_name_heading").css("display", "none");
    $(".all_product").css("display", "block");
  }
  var ggg = <span dangerouslySetInnerHTML={{ __html: productName }} />;
  function autoFill(formvaile) {
    if (tp) {
      firstName = tp.getToken() && tp.getToken().fname;
      lastName = tp.getToken() && tp.getToken().lname;
      emailId = tp.getToken() && tp.getToken().email;
    }

    if (formvaile.model && formvaile.model.name) {
      if (formvaile.model.name.toUpperCase() === "PRODUCTNAME") {
        if (productName) {
          formvaile.valueField.value = productName;
          formvaile.model.value = productName;
        } else {
          formvaile.valueField.value = "All Products";
          formvaile.model.value = "All Products";
        }
      }
      if (formvaile.model.name.toUpperCase() === "FIRSTNAME") {
        formvaile.valueField.value = firstName;
        formvaile.model.value = firstName;
      }
      if (formvaile.model.name.toUpperCase() === "LASTNAME") {
        formvaile.valueField.value = lastName;
        formvaile.model.value = lastName;
      }
      if (formvaile.model.name.toUpperCase() === "EMAILID") {
        formvaile.valueField.value = emailId;
        formvaile.model.value = emailId;
      }
    }
  }
  if (fields) {
    if (fields.fields) {
      fields.fields.map((item) => {
        if (item.fields) {
          item.fields.map((formfield) => {
            if (formfield.fields) {
              formfield.fields.map((formvaile) => {
                return autoFill(formvaile);
              });
            }
            return autoFill(formfield);
          });
        }
        return autoFill(item);
      });
    }
  }

  return (
    <div className="container-fluid leadform_container">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6"></div>
          <div className="col-sm-12 col-md-12 col-lg-6 pr-0">
            <div className="formdiv">
              <div className="overlay">
                <div className="lds-dual-ring loader_center"></div>
              </div>
              <Form
                form={fields}
                sitecoreApiHost={sitecoreApiHost}
                sitecoreApiKey={sitecoreApiKey}
                onRedirect={(url) => history.push(url)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(LeadGenForm);
