import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import util from '../util';

const CTAButton = ({ props, CTAData, CTAType }) => {

  if (CTAData.CTALink) {
    props.value.text = CTAData.CTALink.value.text;
  }
  
  props.value.text = util.setVarLength(props.value.text, 25);

  if (CTAType === 'arrowLeftCTA') {
    return (
      <div className="arrowLeftCTA">
        <Link className="text-white small font-weight-bold" field={props}>
          <span class="fis-icon">
            <svg class="fis-icon-left-arrow fis-text-green">
              <svg class="fis-icon-left-arrow fis-text-green" viewBox="0 0 73 32">
                <path d="M72.131 17.677l-10.899 13.331c-1.063 1.183-2.717 1.289-3.832 0.417s-1.317-2.659-0.426-3.75l7.351-8.998h-61.602c-1.505 0-2.725-1.194-2.725-2.666s1.22-2.666 2.725-2.666h61.602l-7.351-8.998c-0.892-1.091-0.655-2.837 0.426-3.749 1.174-0.992 2.94-0.674 3.832 0.417l10.899 13.331c0.461 0.552 0.588 0.958 0.596 1.666-0.051 0.59-0.277 1.287-0.596 1.666z"></path>
              </svg>
            </svg>
          </span>
          <span class="link">{ props.value.text }</span>
        </Link>
      </div>
    );
  } else {
    return (
      <div className="arrowCTA">
        <Link className="text-white small font-weight-bold" field={props}>
          <span class="link">{ props.value.text }</span>
          <span class="fis-icon">
            <svg class="fis-icon-arrow fis-text-green">
              <svg class="fis-icon-arrow fis-text-green" viewBox="0 0 73 32">
                <path d="M72.131 17.677l-10.899 13.331c-1.063 1.183-2.717 1.289-3.832 0.417s-1.317-2.659-0.426-3.75l7.351-8.998h-61.602c-1.505 0-2.725-1.194-2.725-2.666s1.22-2.666 2.725-2.666h61.602l-7.351-8.998c-0.892-1.091-0.655-2.837 0.426-3.749 1.174-0.992 2.94-0.674 3.832 0.417l10.899 13.331c0.461 0.552 0.588 0.958 0.596 1.666-0.051 0.59-0.277 1.287-0.596 1.666z"></path>
              </svg>
            </svg>
          </span>
        </Link>
      </div>
    );
  }

};

export default CTAButton;