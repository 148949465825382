import React, { useState, useEffect } from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import GenericCtaButton from "../GenericCtaButton";
import createDefaultStore from "../../SessionHandler";
import { createTokenProvider } from "../../AwsTokenAuth";
import sessionconfig from "../../temp/sessionconfig";
import util from "../util";
import $ from "jquery";

const StickyNavigationBrain = (props) => {
  const [tp, setTp] = useState();

  useEffect(() => {
    const storage = createDefaultStore({
      [sessionconfig.session.storageKey]: window.sessionStorage.getItem(
        sessionconfig.session.storageKey
      ),
    });
    setTp(createTokenProvider(sessionconfig.session.storageKey, storage));

    if ($(".sticky").length > 0) {
      let offset = $(".stickyOuter").offset();
      let navParent = $(".stickyOuter");
      let nav = navParent.find(".sticky");
      let tmp = navParent
        .find(".sticky")
        .clone()
        .attr("class", "tmp")
        .css("visibility", "hidden");
      window.addEventListener("scroll", function () {
        if (window.pageYOffset > offset.top) {
          navParent.append(tmp);
          nav.css({ position: "fixed", top: 0 });
        } else {
          navParent.find(".tmp").remove();
          nav.css({ position: "static", top: "" });
        }
      });
    }
  }, []);

  if (props.fields) {
    let {
      Title,
      Backlink,
      BackLinkCTAType,
      Subscriptionlink,
      SubscriptionLinkCTAType,
      RequestSubscriptionLink,
      RequestSubscriptionLinkCTAType,
      Description,
    } = props.fields;

    if (
      (Title.value && Title.value !== null) ||
      (Subscriptionlink.value &&
        Subscriptionlink.value !== null &&
        Subscriptionlink.value.href !== "") ||
      (Backlink.value && Backlink.value !== null && Backlink.value.href !== "")
    ) {
      if (
        Backlink.value &&
        Backlink.value !== null &&
        Backlink.value.href !== ""
      ) {
        Title.value = util.setVarLength(Title.value, 50);
      }

      if (
        Subscriptionlink.value &&
        Subscriptionlink.value !== null &&
        Subscriptionlink.value.href !== ""
      ) {
        Subscriptionlink.value.text = util.setVarLength(
          Subscriptionlink.value.text,
          25
        );
      }

      if (
        RequestSubscriptionLink.value &&
        RequestSubscriptionLink.value !== null &&
        RequestSubscriptionLink.value.href !== ""
      ) {
        RequestSubscriptionLink.value.text = util.setVarLength(
          RequestSubscriptionLink.value.text,
          25
        );
      }

      // if (tp) {
      return (
        <div className="stickyOuter">
          <div className="container-fluid sticky section-bg-blue grad-purple-green">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-7 col-lg-7 navigation">
                  {Backlink.value.text !== undefined &&
                    Backlink.value.text !== "" && (
                      <GenericCtaButton
                        props={Backlink}
                        CTAType={
                          BackLinkCTAType
                            ? BackLinkCTAType.fields.ClassName.value
                            : "tertiary-left-icon"
                        }
                      />
                    )}
                  <Text tag="h1" field={Title} />
                </div>
                <div className="content MuiGrid-container60 col-sm-12 col-md-7 col-lg-7">
                  <RichText field={Description} />
                
                   </div>

                {tp &&
                  tp.isLoggedIn() &&
                  Subscriptionlink.value.text !== undefined &&
                  Subscriptionlink.value.text !== "" && (
                    <div className=" navigation-right">
                      <div className="valign">
                        <div
                          className="simpllink"
                          onClick={() =>
                            util.setProductName(props.fields.Title.value)
                          }
                        >
                          <GenericCtaButton
                            props={Subscriptionlink}
                            CTAType={
                              SubscriptionLinkCTAType
                                ? SubscriptionLinkCTAType.fields.ClassName.value
                                : "primary"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {tp &&
                  !tp.isLoggedIn() &&
                  RequestSubscriptionLink.value.text !== undefined &&
                  RequestSubscriptionLink.value.text !== "" && (
                    <div className="navigation-right">
                      <div className="valign">
                        <div
                          className="simpllink"
                          onClick={() =>
                            util.setProductName(props.fields.Title.value)
                          }
                        >
                          <GenericCtaButton
                            props={RequestSubscriptionLink}
                            CTAType={
                              RequestSubscriptionLinkCTAType
                                ? RequestSubscriptionLinkCTAType.fields
                                    .ClassName.value
                                : "primary"
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      );
      // }
      // else {
      //   return <div></div>;
      // }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default StickyNavigationBrain;
