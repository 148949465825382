import React from 'react';
//import { Text } from '@sitecore-jss/sitecore-jss-react';
//import GenericProductTile from '../GenericProductTile/index'

const RecommendedTools = (props) => {
  //const { productList } = props.fields;

  return(
    <div></div>
    // <div className="container recommended-container">
    //   <Text tag='h3' field={props.fields.heading} />
    //   <div className='row listItem'>
    //       {productList &&
    //         productList.slice(0, 2).map((listItem, index) => (
    //           <div className='col-md-4 text-center' key={"t-"+index}>
    //            <GenericProductTile props={listItem}  
    //            productProperty={{"isRecommended":true,"isSubscribed":false, "productTileBGColor": "#003e4a"}}  />
    //           </div>
    //         )
    //       )
    //     }
    //   </div>
    // </div>
  )
};

export default RecommendedTools;
