import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import util from '../util';

const ProductDetails = (props) => {

  if (props.fields) {

    let { ProductTitle, Description } = props.fields;

    ProductTitle.value = util.setVarLength(ProductTitle.value, 50);
    Description.value = util.setVarLength(Description.value, 750);

    return (
      <div className="product_info col-md-12" style={{ paddingTop: "30px" }}>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <Text tag="h2" field={ProductTitle} />
                <RichText field={Description} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }

};

export default ProductDetails;
