import React from 'react';
import { Text, Image, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import util from '../util';

const ReportHeader = (props) => {
  
  if (props.fields) {

    let { pageTitle, Title, Description, DownloadCTA } = props.fields;
    let imageIcon = props.fields.Image;

    return (
      <div className="container-fluid small pb-0 herobanner">
        <div className="container1 section-cecl">
          <div className="row">
            <div className="col-md-3">
              <Text tag="h1" id="rhpTitle" field={pageTitle} />
            </div>
          </div>
          <div className="cecl-top">
            <div className="row">
              {(imageIcon.value.src) && ( 
                <div className="icon">
                  <Image field={imageIcon} />
                </div>
              )}
              <div className="content">
                <Text id="rhcTitle" tag="label" className="title" field={Title} />
                <RichText id="rhcDescription" className="description" field={Description} />
                {(DownloadCTA.value.text) && 
                (DownloadCTA.value.text !== "") && (
                  <div className="btn-div">
                    <a id="rhcLink" className="btn-primary btn-download" onClick={() => { util.downloadPdf(DownloadCTA.value.href, 'Quarter_report.pdf') }}>{DownloadCTA.value.text}</a>
                  </div>
                )} 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }

};

export default ReportHeader;
