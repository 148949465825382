import jwt from 'jwt-decode';
import sessionconfig from './temp/sessionconfig';

export const createAuthProvider = (sessionStorageKey, storage) => {
    
    const tp = createTokenProvider(sessionStorageKey, storage);
    
    const login = (newTokens, callback) => {

        if (newTokens) {
            const access_token = newTokens.replace('#access_token=', '');
            const id_token = access_token.substring(access_token.indexOf('&id_token=') + 1);
            const decode_token = jwt(id_token.replace('id_token=', ''));
            const userDetails = {
                "fname": decode_token['custom:FirstName'],
                "lname" : decode_token['custom:LastName'],
                "email" : decode_token.email,
                "authTime" : decode_token.auth_time,
                "expireTime" : decode_token.exp
            }
            tp.setToken(userDetails);
            if ( window.sessionStorage.getItem('requested_url') && (window.sessionStorage.getItem('requested_url').indexOf('null') < 0) ) {
                let return_url = window.sessionStorage.getItem('requested_url');
                window.sessionStorage.setItem('requested_url', null);
                window.location.assign(return_url);
            }
        } else {
            if( ((window.location.pathname.indexOf(sessionconfig.path.profileURL) > -1) || 
            (window.location.pathname.indexOf(sessionconfig.path.ceclURL) > -1)) ) {
                window.sessionStorage.setItem('requested_url', window.location.pathname);
                if ( !tp.getToken() ) {
                    let session_url = sessionconfig.aws.endpoint + sessionconfig.app.url;
                    callback(session_url); 
                } else {
                    window.sessionStorage.setItem('requested_url', null);
                }
            } else if ((window.location.pathname.indexOf(sessionconfig.path.logoutURL) > -1)) {
                tp.setToken(null);
                window.location.assign('/');
            } else if (window.location.pathname === '/') {
                if (window.self !== window.top) {
                    tp.setToken(null);
                }
            }
        }
    };

    return {
        login
    };

};

export const createTokenProvider = (sessionStorageKey, storage) => {

    const getTokenInternal = () => {
        const data = storage.getItem(sessionStorageKey);
        const token = (data && JSON.parse(data)) || null;
        return token;
    };

    const getToken = () => {
        return getTokenInternal();
    };

    const setToken = (token) => {
        if (token) {
            storage.setItem(sessionStorageKey, JSON.stringify(token));
        } else {
            storage.removeItem(sessionStorageKey);
        }
    };

    const isLoggedIn = () => {
        return !!getTokenInternal();
    };

    return {
        getToken,
        setToken,
        isLoggedIn
    };
};