import React from "react";
import { Text, RichText } from "@sitecore-jss/sitecore-jss-react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import arrowDownImg from "../assets/images/arrow_down.png";
import config from "../temp/config";

function getValueByPath(obj, path) {
  let result = obj;
  for (let i = 0; i < path.length; i++) {
    try {
      result = result[path[i]];
    } catch (e) {
      return undefined;
    }
  }
  return result;
}

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];

    if (sortConfig !== null) {
      const sortkey = sortConfig.key.split(".");
      sortableItems.sort((a, b) => {
        let aValue = getValueByPath(a, sortkey);
        let bValue = getValueByPath(b, sortkey);
        if (bValue > aValue) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }

        if (bValue < aValue) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }

        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);
  const requestSort = (key) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};

//----------GraphQL Query------------//
const GET_PRODUCTS = gql`
  query {
    item(path: "/sitecore/content/Ethos/Marketplace") {
      children(includeTemplateIDs: "E7F1AB64BA6846AEB39C950668775DCA") {
        name
        ... on ProductPage {
          id
          productTitle {
            value
          }
          productStatus {
            targetItem {
              id
              name
            }
          }
          productId {
            value
          }
          learnMore {
            url
            text
          }
          isActive {
            boolValue
          }
        }
      }
    }
  }
`;

const ProList = (props) => {
  const { data } = useQuery(GET_PRODUCTS);
  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    if (data) {
      setState(data.item.children);
    }
  }, [data]);

  const products = state;
  products.sort((a, b) => {
    if (a.productTitle.value < b.productTitle.value) {
      return -1;
    }
    if (a.productTitle.value > b.productTitle.value) {
      return 1;
    }
    return 0;
  });
  const { items, requestSort, sortConfig } = useSortableData(products);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  if (config.productConfig) {
    var SubscribedItemId = config.productConfig.productSortOrder[2]
      .replace(/-/g, "")
      .toUpperCase();
    var otherItemId = config.productConfig.productSortOrder[0]
      .replace(/-/g, "")
      .toUpperCase();
  }
  function getProductList(SubscribedValue) {
    if (items && items.length > 0) {
      if (config.productConfig) {
        var SubscribedItemId = config.productConfig.productSortOrder[2]
          .replace(/-/g, "")
          .toUpperCase();
        var otherItemId = config.productConfig.productSortOrder[0]
          .replace(/-/g, "")
          .toUpperCase();
      }
      return items
        .filter((item) => {
          let isSubscribed = false;
          if (item.isActive.boolValue === true) {
            if (item.productStatus.targetItem) {
              isSubscribed = item.productStatus.targetItem.id;
            }
            if (SubscribedValue === SubscribedItemId) {
              return isSubscribed === SubscribedItemId;
            }
            if (SubscribedValue === otherItemId) {
              return isSubscribed !== SubscribedItemId;
            }
          }
          return false;
        })
        .map((item, index) => {
          let isSubscribed = false;
          if (item.productStatus.targetItem) {
            isSubscribed = item.productStatus.targetItem.id;
          }
          let productName = "";
          if (item.productTitle !== undefined) {
            productName = item.productTitle.value;
          }
          let productURL = "";
          if (item.learnMore) {
            // productURL = item.learnMore.url;
            productURL = "#";
          }

          let Subscribed = "";
          if (isSubscribed === SubscribedValue) {
            Subscribed = true;
          } else {
            Subscribed = false;
          }
          if (productName !== "") {
            return (
              <tr key={index}>
                <td>
                  <div>
                    {isSubscribed === SubscribedItemId ? (
                      <span>
                        <input
                          type="checkbox"
                          disabled
                          defaultChecked={Subscribed}
                        />
                      </span>
                    ) : (
                      <span>
                        <input type="checkbox" disabled />
                      </span>
                    )}
                    <span className="product_name">
                      <span dangerouslySetInnerHTML={{ __html: productName }} />
                    </span>
                  </div>
                </td>
                <td className="text-right">
                  <a href={productURL}>View Details</a>
                </td>
              </tr>
            );
          }
          return item;
        });
    }
  }
  let abd = "Ethos<sup>TM</sup> Analyst Library";
  return (
    <table className="table table-striped products-table table-borderless m-0">
      <thead>
        <tr>
          <th scope="col" className="pl-5">
            {props.prodata.ProductNameLable.value}
            <a
              href="#"
              onClick={() => requestSort("productTitle.value")}
              className={getClassNamesFor("productTitle.value")}
            >
              <img
                src={arrowDownImg}
                alt="dropdown-arrow"
                className="dropdown-arrow"
              />
            </a>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {getProductList(SubscribedItemId)}
        {getProductList(otherItemId)}
      </tbody>
    </table>
  );
};

export default ProList;
