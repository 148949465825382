import React from 'react';
import GenericProductTile from '../GenericProductTile';
import PromotionalCard from '../PromotionalCard';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import config from '../../temp/config';
import createDefaultStore from '../../SessionHandler';
import { createTokenProvider } from '../../AwsTokenAuth';
import sessionconfig from '../../temp/sessionconfig';

function getIndex(value, arr) {

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === value) {
      return i;
    }
  }

  return -1;

}

function sortProductList(unsortProductList) {

  let productSortOrder = config.productConfig.productSortOrder;

  const sortProductLists = [...unsortProductList].sort( (a, b) => 
  ( getIndex((a.fields.ProductStatus !== null ? a.fields.ProductStatus.id : ""), productSortOrder) < getIndex((b.fields.ProductStatus !== null ? b.fields.ProductStatus.id : ""), productSortOrder)) ? 1 : -1);
  
  return sortProductLists;

}

function getProductType(item, isLoggedIn) {

  let productProperty;

  if (!isLoggedIn) {
    productProperty = {
      isRecommended: false,
      isSubscribed: false,
      class: config.productConfig.AllProducts.className,
      title: config.productConfig.AllProducts.title
    };
  } else {
    productProperty = {
      isRecommended: ((item.fields.ProductStatus !== null) && (item.fields.ProductStatus.id === config.productConfig.productRecommendedItemId)) ? true : false,
      isSubscribed: ((item.fields.ProductStatus !== null) && (item.fields.ProductStatus.id === config.productConfig.productSubscribedItemId)) ? true : false,
      class: ((item.fields.ProductStatus === null) || (item.fields.ProductStatus.fields.StyleClass === null)) ? "" : item.fields.ProductStatus.fields.StyleClass.fields.className.value,
      title: (item.fields.ProductStatus === null) ? "" : item.fields.ProductStatus.fields.Title.value
    };
  }

  return productProperty;

}

export default class EthosProductList extends React.Component {

  constructor(props) {

    super(props)

    this.state = {
      tp: ''
    }

  }

  componentDidMount() {
    
    const storage = createDefaultStore({ [sessionconfig.session.storageKey]: window.sessionStorage.getItem(sessionconfig.session.storageKey) });
    this.setState({ tp : createTokenProvider(sessionconfig.session.storageKey, storage) });
  
  }

  render() {

    if (this.props.fields) {
      
      const { tp } = this.state;
      let { ProductList, Heading } = this.props.fields;
      let promotional_card = this.props.fields.PromotionalCard;

      if (tp) {

        let productList = [];
        
        if (tp.isLoggedIn()) {
          productList = sortProductList(ProductList);
        } else {
          productList = ProductList;
        }

        return (
          <div className="container-fluid product-container">
            <div className="container">
              {(productList.length > 0) && (
                <div>
                  <div className="row">
                    <RichText tag="h2" className="col-md-12 pt-2 my-3 text-white" field={Heading} />
                  </div>
                  <div className="row">
                    {(productList) && productList.map((listItem, index) => (
                          

                      <React.Fragment key={index}>
                        {
                        ((listItem.fields.Collection.value) === 'false')
                         ? 
                         (<div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
                          <GenericProductTile 
                            props={listItem} 
                            productProperty={getProductType(listItem, tp.isLoggedIn())} 
                            userLoggedIn={tp.isLoggedIn()} 
                          />
                        </div>)
                        :
                        (<PromotionalCard data={listItem} />)
                          
                        }
                        
                      </React.Fragment>
                    ))}


                  </div>
                </div>
              )}

              {(productList.length === 0) && (
                <div className="row col-md-12 no-data text-white">
                  <h2> No data available as of now.</h2>
                </div>
              )}
            </div>
          </div>
        ); 
      } else {
        return (
          <div className="container-fluid product-container loader-div">
            <div className="container">
              <div className="loader"></div>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }

  }
  
};
