import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import util from '../util';

const ProductInfoLevel = (props) => {

  if (props.fields) {

    let { LevelTitle, LevelDescription } = props.fields;
    LevelTitle.value = util.setVarLength(LevelTitle.value, 50);
    LevelDescription.value = util.setVarLength(LevelDescription.value, 750);

    return (
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Text tag="h2" field={LevelTitle} />
              <RichText field={LevelDescription} />
            </div>
          </div>
        </div>
      </div>
    );

  } else {
    return null;
  }

};

export default ProductInfoLevel;
