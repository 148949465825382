import React from 'react';
import { Text, RichText, Image,Link } from '@sitecore-jss/sitecore-jss-react';
import arrow from '../../assets/images/arrow.svg';
import GenericCtaButton from "../GenericCtaButton";
import util from "../util";

const BrainFeatureBanner = (props) => {

  if (props.fields) {
    
    let { 
      
      LearnMore,
      Heading,
      CallToActionCTAType
     

    } = props.fields;

  

    return (


<div className="stickyOuter">
          <div className="container-fluid sticky section-bg-blue grad-purple-green">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-7 col-lg-7 navigation">
                <RichText field={Heading} />
                <div className="CTA-container">
              <GenericCtaButton
                props={LearnMore}
                CTAType={
                  CallToActionCTAType
                    ? CallToActionCTAType.fields.ClassName.value
                    : "tertiary-w-icon"
                }
              />
            </div>
                </div>

                
              
              </div>
            </div>
          </div>
        </div>
				
    );

  } else {
    return null;
  }

};

export default BrainFeatureBanner;
