import React from 'react';
//import { Text } from '@sitecore-jss/sitecore-jss-react';
//import GenericProductTile from '../GenericProductTile/index'

const SubscribeTools = (props) => {
  //const { productList } = props.fields;

  return(
    <div>
      {/* <div className="container subscribe-container">
        <Text tag='h3' field={props.fields.heading} />
        <div className='row listItem'>
            {productList &&
              productList.slice(0, 2).map((listItem, index) => (
              //API         
                <div className='col-md-4 text-center' key={"t-"+index}>
                <GenericProductTile props={listItem}  
                  productProperty={{"isRecommended":false,"isSubscribed":true, "productTileBGColor": "#005974"
                }}  />
                </div>
              )
            )
          }
        </div>
      </div>
      <div className='clear'></div> */}
    </div>
  )
};

export default SubscribeTools;
