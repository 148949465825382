import React from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';

const ContentSpot = (props) => {

  if (props.fields) {

    let { Title, Description } = props.fields;

    return (
      <div className="container-fluid contentspot_style">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 my-5 left_col">
              <Text tag="h2" className="ContentSpot_title" field={Title} />
              <RichText className="ContentSpot_text" field={Description} />
            </div>
          </div>
        </div>
      </div>
    );

  } else {
    return null;
  }

};

export default ContentSpot;
