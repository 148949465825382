import React from "react";
import { RichText } from "@sitecore-jss/sitecore-jss-react";
import createDefaultStore from "../../SessionHandler";
import { createTokenProvider } from "../../AwsTokenAuth";
import sessionconfig from "../../temp/sessionconfig";

export default class FullWidthColumn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tp: "",
    };
  }

  componentDidMount() {
    const storage = createDefaultStore({
      [sessionconfig.session.storageKey]: window.sessionStorage.getItem(
        sessionconfig.session.storageKey
      ),
    });
    this.setState({
      tp: createTokenProvider(sessionconfig.session.storageKey, storage),
    });
  }

  render() {
    if (this.props.fields !== undefined) {
      const { tp } = this.state;
      let auth_url = sessionconfig.aws.endpoint + sessionconfig.app.url;
      let { profileURL, logoutURL } = sessionconfig.path;
      let { Content, AuthenticatedContent } = this.props.fields;

      return (
        <div className="container-fluid herobanner">
          <div className="main-header">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-8 col-xl-10">
                <RichText field={Content} />
                {tp && (
                  <React.Fragment>
                    {tp.isLoggedIn() && (
                      <RichText field={AuthenticatedContent} />
                    )}
                  </React.Fragment>
                )}
              </div>
              {tp && (
                <div className="col-sm-12 col-md-6 col-lg-4 col-xl-2 text-right">
                  {!tp.isLoggedIn() && (
                    <div className="row profile-row valign">
                      <div class="makeStyles-login-button-3">
                        <a
                          href={auth_url}
                          className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
                        >
                          <div class="makeStyles-icons-4">
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 29"
                              version="1.1"
                            >
                              <title>LockIcon</title>
                              <g
                                id="lock-icon-svg-wrapper"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <g
                                  id="lock-icon-svg"
                                  transform="translate(-1719.000000, -26.000000)"
                                  stroke="#285bc5"
                                  stroke-width="2"
                                >
                                  <g
                                    id="Anon-Menu"
                                    transform="translate(50.000000, 16.000000)"
                                  >
                                    <g
                                      id="Sign-In-Button"
                                      transform="translate(1622.000000, 0.000000)"
                                    >
                                      <g
                                        id="icon_lock"
                                        transform="translate(48.000000, 11.500000)"
                                      >
                                        <path
                                          d="M21.0223329,25.5 L0.979326996,25.5 C0.43986721,25.5 0,25.0804196 0,24.5638907 L0,11.4361093 C0,10.9195804 0.43986721,10.5 0.979326996,10.5 L21.0223329,10.5 C21.5617927,10.5 22,10.9195804 22,11.4361093 L22,24.5638907 C22,25.0804196 21.5617927,25.5 21.0223329,25.5 Z"
                                          id="Stroke-1"
                                        ></path>
                                        <path
                                          d="M4,10.5 L4,7.60427097 C4,3.68018095 7.32403733,0.501190813 11.425185,0.501190813 L11.574815,0.501190813 C15.436233,0.428836073 18.9404698,3.66324687 19,7.60427097 L19,10.5"
                                          id="Stroke-3"
                                        ></path>
                                        <path
                                          d="M9,10.5 L9,8.42614573 C9,6.81058358 10.1081684,5.5 11.4742124,5.5 L11.5243936,5.5 C12.8918316,5.5 14,6.81058358 14,8.42614573 L14,10.373063"
                                          id="Stroke-5"
                                        ></path>
                                        <path
                                          d="M13,17.500794 C13,18.6044065 12.1044065,19.5 11.000794,19.5 C9.89559349,19.5 9,18.6044065 9,17.500794 C9,16.3971814 9.89559349,15.5 11.000794,15.5 C12.1044065,15.5 13,16.3971814 13,17.500794 Z"
                                          id="Stroke-7"
                                        ></path>
                                        <polyline
                                          id="Stroke-9"
                                          points="11.5 19.5 11.5 20.8985596 11.5 22.5"
                                        ></polyline>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <span>Sign In</span>
                        </a>
                      </div>
                    </div>
                  )}
                  {tp.isLoggedIn() && (
                    <div className="row profile-row">
                      <div className="dropdown valign">
                        <a
                          href="/#"
                          className="dropdown-toggle user"
                          data-toggle="dropdown"
                        >
                          Welcome {tp.getToken().fname} {tp.getToken().lname}
                        </a>
                        <div className="dropdown-menu">
                          <a href={profileURL} className="dropdown-item">
                            My Profile
                          </a>
                          <a href={logoutURL} className="dropdown-item">
                            Logout
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
