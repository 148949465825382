import React, { useState, useEffect } from 'react';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { Modal } from 'react-bootstrap';
import sessionconfig from '../../temp/sessionconfig';

const Video = (props) => {
  
  let { VideoThumbnail, VideoTitle, VideoID, PlayIcon } = props.fields;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const useScript = url => {
    useEffect(() => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      }
    }, [url]);
  };

  const showCloseBtn = () => {
    document.getElementById('closeBtn').style.visibility = 'visible';
  }

  const hideCloseBtn = () => {
    document.getElementById('closeBtn').style.visibility = 'hidden';
  }

  useScript(sessionconfig.wistia.endpoint + VideoID.value + '.jsonp');
  useScript(sessionconfig.wistia.script);
  
  if (props.fields) {

    return (
      <div className="container-fluid p-0">
        {( (VideoThumbnail.value.src !== undefined) && 
        (VideoThumbnail.value.src !== "") ) && (
          <div className="video-container">
            <Image className="thumbnail" field={VideoThumbnail} />
            {( (VideoID.value !== null) && 
            (VideoID.value !== "") && 
            (VideoID.value !== undefined) ) && (
              <Modal className="video-modal" show={show} onHide={handleClose} size="lg" onMouseEnter={showCloseBtn} onMouseOut={hideCloseBtn}>
                <Modal.Header id="closeBtn" closeButton></Modal.Header>
                <Modal.Body onMouseOver={showCloseBtn} onMouseOut={hideCloseBtn}>
                  <div id="video-modal" className="wistia_responsive_padding">
                    <div className="wistia_responsive_wrapper">
                        <div className={`wistia_embed wistia_async_${VideoID.value} videoFoam=true autoPlay=true`}>
                          <div className="wistia_swatch">
                            <img src={`${sessionconfig.wistia.endpoint} + ${VideoID.value}/swatch`} alt="video-image" aria-hidden="true"/>
                          </div>
                        </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            )}
            {( (VideoID.value !== null) && 
            (VideoID.value !== "") && 
            (VideoID.value !== undefined) && 
            (PlayIcon.value.src !== undefined) && 
            (PlayIcon.value.src !== "") ) && (
              <div className="circle">
                <Image className="play-button" field={PlayIcon} alt="play-button" onClick={handleShow} />
              </div>
            )}
            <div className="container videoContent">
              <div>
                <Text className="text-white" tag="h3" field={VideoTitle} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
  
};

export default Video;
