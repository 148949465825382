import React from "react";
import { Text, Placeholder } from "@sitecore-jss/sitecore-jss-react";

const Dynamicplaceholder = (props) => {
  if (props.fields) {
    var headlinetext = props.fields.headline;
  }
  console.log(headlinetext);
  return (
    <div className="container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {headlinetext.value && (
              <Text tag="h1" field={props.fields.headline} />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <Placeholder name="jss-left" rendering={props.rendering} />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <Placeholder name="jss-right" rendering={props.rendering} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dynamicplaceholder;
