import React from 'react';
import { Link } from '@sitecore-jss/sitecore-jss-react';

const GenericCtaButton = ({ props, CTAType = null, CTAData = null, productName = null }) => {

  if (props) {

    if (productName !== null) {
      localStorage.setItem('productName', productName);
    }

    if (CTAData !== null && CTAData.CTALink) {
      props.value.text = CTAData.CTALink.value.text;
    }

    if ((CTAType === null) || (
      (CTAType !== 'primary') &&
      (CTAType !== 'secondary') &&
      (CTAType !== 'tertiary-w-icon') &&
      (CTAType !== 'tertiary-left-icon')
    )) {
      CTAType = 'primary';
    }

    if (CTAType === 'tertiary-left-icon') {

      return (
        <div className="arrowLeftCTA">
          <Link className="text-white font-weight-bold" field={props}>
            <span className="fis-icon">
              <svg className="fis-icon-left-arrow fis-text-green">
                <svg className="fis-icon-left-arrow fis-text-green" viewBox="0 0 29 16">
                  <title></title>
                  <polygon className="st0" points="28,6.5 6.6,6.5 10.1,3 8,0.9 0.9,7.9 8,15.1 10.1,13 6.7,9.5 28,9.5 "/>
                  {" "}
                </svg>
              </svg>
            </span>
            <span className="link">{ props.value.text }</span>
          </Link>
        </div>
      );

    } else if (CTAType === 'tertiary-w-icon') {

      return (
        <div className="arrowCTA">
          <Link className="text-white font-weight-bold" field={props}>
            <span className="link">{ props.value.text }</span>
            <span className="fis-icon">
              <svg className="fis-icon-arrow fis-text-green">
                <svg className="fis-icon-arrow fis-text-green" viewBox="0 0 73 32">
                  <title></title>
                  <path d="M72.131 17.677l-10.899 13.331c-1.063 1.183-2.717 1.289-3.832 0.417s-1.317-2.659-0.426-3.75l7.351-8.998h-61.602c-1.505 0-2.725-1.194-2.725-2.666s1.22-2.666 2.725-2.666h61.602l-7.351-8.998c-0.892-1.091-0.655-2.837 0.426-3.749 1.174-0.992 2.94-0.674 3.832 0.417l10.899 13.331c0.461 0.552 0.588 0.958 0.596 1.666-0.051 0.59-0.277 1.287-0.596 1.666z"></path>
                </svg>
              </svg>
            </span>
          </Link>
        </div>
      );

    } else if (CTAType === 'secondary') {

      return <Link className="btn-primary-white-on-light" field={props} />;
    
    } else {
    
      return <Link className="btn-primary-green-on-dark" field={props} />;
    }

  } else {
    return null;
  }
  
};

export default GenericCtaButton;
