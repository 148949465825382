import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import config from "../../temp/config";

import util from "../util";



const GenericProductPage2 = (props) => (
 
        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4">
        <div  className="product-tile text-white unauthenticated-tile">
          <div className="tile-content text-center " >
          <Text field={props.fields.ProductContent} />
          <div className="icon text-center">
            <Image field={props.fields.ProductImage} className="tile-image" />
          </div>
         
      </div>
    </div>
  </div>
);

export default GenericProductPage2;
